import React from "react";
import { Link } from "react-router-dom";

const Breadcrum = ({ active_page, prev_sub_page, sub_page }) => {
  return (
    <section className="breadcrumb-section">
      <nav aria-label="breadcrumb">
        <h2>{active_page}</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          {prev_sub_page && (
            <li className="breadcrumb-item">
              <Link
                to={
                  prev_sub_page.state
                    ? {
                        pathname: prev_sub_page.link,
                        state: prev_sub_page.state || null,
                      }
                    : prev_sub_page.link
                }
              >
                {prev_sub_page.text}
              </Link>
            </li>
          )}
          {sub_page && (
            <li className="breadcrumb-item">
              <Link
                to={
                  sub_page.state
                    ? { pathname: sub_page.link, state: sub_page.state || null }
                    : sub_page.link
                }
              >
                {sub_page.text}
              </Link>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            {active_page}
          </li>
        </ol>
      </nav>
    </section>
  );
};

export default Breadcrum;
