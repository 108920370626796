import React, { createContext, useState, useContext } from "react";

// Create a context
const FormContext = createContext();

// Create a provider component
export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    full_name: "",
    company: "",
    country: "",
    state: "",
    address: "",
    pincode: "",
    mobileno: "",
    email: "",
    additionalInfo: "",
  });

  // Update form data
  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

// Create a custom hook to use the form context
export const useFormContext = () => {
  return useContext(FormContext);
};
