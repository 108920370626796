import React, { useState, useEffect } from "react";
import Navbar from "../NavbarComponents/Navbar";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import axiosConfig from "../../Service/axiosConfig";
import { FaStar, FaArrowRight } from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";

const SearchCourse = () => {
  const [searchParams] = useSearchParams();
  const [courses, setCourses] = useState([]);
  const query = searchParams.get("query");
  const navigate = useNavigate();
  const truncateDescription = (text, maxLength = 150) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Fetch courses based on the search query
  const getSearchCourse = async () => {
    if (!query) return;

    try {
      const response = await axiosConfig.get(
        `/accounts/course/?search=${query}`
      );
      if (response.data) {
        setCourses(response.data.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearchCourse();
  }, [query]);

  const handleNavigateToCourseDetail = (courseId) => {
    navigate(`/coursedetail/${courseId}/`);

    console.log(`Navigate to course ${courseId}`);
  };

  return (
    <>
      <Navbar />
      <section>
        <div className="container">
          <div className="row">
            {courses.map((course, index) => (
              <div className="col-md-3" key={index}>
                <div
                  className="card mt-5"
                  onClick={() => handleNavigateToCourseDetail(course.id)}
                >
                  <div>
                    {course?.image?.image ? (
                      <img
                        src={course.image.image}
                        alt={course.title}
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src="default-image.jpg"
                        alt={course.title}
                        className="img-fluid"
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <button className="card-btn mt-24">{course.name}</button>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <FaStar size={20} color={"#FFC224"} className="mt-24" />
                      </div>
                      <div className="mt-24 reviews">
                        ({course.rating} Reviews)
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="h3-black mt-15">
                      {truncateDescription(course.description, 50)}
                    </h3>
                  </div>
                  <div>
                    <p className="cd-p">
                      By {course.trainer_ref.user.full_name}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <button
                        className="en-now d-flex align-items-center pt-11 pl-20 pb-11"
                        // onClick={() => handleAdd(course)}
                      >
                        Enroll Now
                        <IoIosArrowRoundForward
                          size={20}
                          style={{ marginLeft: "8px" }}
                        />
                      </button>
                    </div>
                    <div>
                      <span className="card-price">${course.fee}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchCourse;
