import React, { useState, useEffect } from "react";
import Navbar from "../NavbarComponents/Navbar";
import Footer from "../Pages/Footer";
import "../Styles/CourseDetail.css";
import {
  PiClockThin,
  PiBookOpenThin,
  PiPlayCircleThin,
  PiBookLight,
} from "react-icons/pi";
import { FaStar } from "react-icons/fa";
import { BsCheckCircle } from "react-icons/bs";
import { useParams } from "react-router-dom";
import axiosConfig from "../../Service/axiosConfig";
import { addToCart, removeFromCart } from "../../redux/CartSlice";
import { useDispatch } from "react-redux";

const CourseDetail = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [similarCourses, setSimilarCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCourseDetail = async () => {
      setLoading(true);
      try {
        const response = await axiosConfig.get(`/accounts/course/${id}/`);
        setCourse(response.data);
      } catch (error) {
        console.error("Error fetching course details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetail();
  }, [id]);
  useEffect(() => {
    const fetchSimilarCourses = async () => {
      try {
        setLoading(true);
        const response = await axiosConfig.get(
          `/catlog/get_similar_coursers/?course_id=${id}`
        );
        const data = response.data;
        console.log(data, "re");
        setSimilarCourses(data);
        setLoading(false);
      } catch (error) {
        console.log(error, "error");
        setError(error);
      }
    };

    fetchSimilarCourses();
  }, [id]);

  const handleAdd = (course) => {
    console.log(course);
    const {
      name,
      description,
      image: { image },
      fee,
      id,
    } = course;
    dispatch(addToCart({ name, description, image, fee, id, quantity: 1 }));
  };
  return (
    <div>
      <>
        <Navbar />
        <section className="course-padding">
          <div className="container">
            <div className="row responsive-view">
              <div className="col-md-8 ">
                <h1 className="detail-h1">{course?.name}</h1>
                <p className="crs-p">
                  {/* This masterclass course is dedicated to anyone wanting to
                  learn how to Digital Marketing By watching My Classes Be
                  ready...<a href="#">Show more</a> */}
                  {course?.description}
                </p>
                <div className="d-flex m-30">
                  <div className="d-flex mr">
                    <img
                      src={require("../images/round-man.webp")}
                      className="img-fluid flex-img1 mr-16"
                    />
                    <div className="d-flex flex-column">
                      <small className="crs-p">Lecturer</small>
                      <big>Lohith Kumar</big>
                    </div>
                  </div>
                  <div className="d-flex flex-column mr">
                    <small className="crs-p">Students Enrolled</small>
                    <big className="b-free">1,477</big>
                  </div>
                  <div className="d-flex flex-column mr">
                    <div>
                      <FaStar size={15} color={"#FFA800"} />
                      <FaStar size={15} color={"#FFA800"} />
                      <FaStar size={15} color={"#FFA800"} />
                      <FaStar size={15} color={"#FFA800"} />
                      <FaStar size={15} color={"#B3BAC2"} />
                      <span className="b-free">4</span>
                    </div>
                    <small className="crs-p">1 review</small>
                  </div>
                </div>
                <img
                  // src={require("../images/DJ.jpeg.webp")}
                  src={course?.image?.image}
                  className="img-fluid adv-img"
                />
                <p className="crs-p mt-24">
                  learn islam learn islam learn islam learn islam learn islam
                  learn islamlearn islam learn islam learn islam learn
                  islamvlearn islamvvv learn islamlearn islam learn islamlearn
                  islamlearn islamlearn islamlearn islamlearn islamlearn islam
                  learn islam learn islam learn islam learn islam vv vlearn
                  islam learn islam learn islam learn islam learn islam learn
                  islam learn islam learn islam learn islam learn islam learn
                  islam learn islam learn islam learn islam learn islam learn
                  islam vlearn islam learn islam .
                </p>
                <h2 className="course-h2">What you’ll learn</h2>
                <ul className="crs-list">
                  <li>Learn How To DJ for Beginners</li>
                  <li>
                    Get ready to start your first gig with no pre-recorded dj
                    set.
                  </li>
                  <li>
                    DJ Courses for Beginners. Learn the skills to dj with your
                    ears.
                  </li>
                </ul>
                <h2 className="course-h2">What is the target audience?</h2>
                <ul className="crs-list">
                  <li>First time experience with dj equipment</li>
                  <li>Introduction first steps for deejaying.</li>
                  <li>Beginners DJs</li>
                </ul>
                <h3 className="course-h3">Related courses</h3>
                <hr />
                {similarCourses.length > 0 ? (
                  <div className="d-flex justify-content-evenly align-items-center g-30 res-col">
                    {similarCourses.map((course) => (
                      <div className="cd" key={course.id}>
                        <img
                          src={course.image.image}
                          className="img-fluid"
                          alt={course.title}
                        />
                        <h4 className="crs-h4 mt-20">{course.description}</h4>
                        <div className="d-flex align-items-center">
                          {course.discountedPrice ? (
                            <>
                              <strike>₹{course.originalPrice}</strike>
                              <big className="b-price">
                                ₹{course.discountedPrice}
                              </big>
                            </>
                          ) : (
                            <big className="b-free mr-20">{course.fee}</big>
                          )}

                          {Array.from({ length: 5 }, (_, index) => (
                            <FaStar
                              key={index}
                              size={15}
                              color={
                                index < course.rating ? "#FFC224" : "#B3BAC2"
                              }
                              className="gap-5"
                            />
                          ))}
                        </div>

                        <p className="crs-demo mt-10">
                          By{" "}
                          {course.trainer_ref.user.full_name ||
                            "Demo Instructor"}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No Similar Courses</div>
                )}
                {/* <div className="d-flex justify-content-evenly align-items-center g-30 res-col">
                  <div className="cd">
                    <img
                      src={require("../images/Link.webp")}
                      className="img-fluid"
                      alt="camera"
                    />
                    <h4 className="crs-h4 mt-20">Basics of MasterStudy</h4>
                    <div className="d-flex  align-items-center">
                      <big className="b-free mr-20">Free</big>
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                    </div>
                    <p className="crs-demo mt-10">By Demo Instructor</p>
                  </div>
                  <div className="cd">
                    <img
                      src={require("../images/Link (1).webp")}
                      className="img-fluid"
                      alt="camera"
                    />
                    <h4 className="crs-h4 mt-20">
                      The Right Set for Landscape Photography
                    </h4>
                    <div className="d-flex  align-items-center">
                      <strike>₹65.99</strike>
                      <big className="b-price">₹35.99</big>
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#B3BAC2"} />
                    </div>
                    <p className="crs-demo mt-10">By Demo Instructor</p>
                  </div>
                  <div className="cd">
                    <img
                      src={require("../images/camera.webp")}
                      className="img-fluid"
                      alt="camera"
                    />
                    <h4 className="crs-h4 mt-20">
                      How to get comfortable on camera
                    </h4>
                    <div className="d-flex align-items-center">
                      <strike>₹65.99</strike>
                      <big className="b-price">₹35.99</big>

                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                      <FaStar className="gap-5" size={15} color={"#FFC224"} />
                    </div>
                    <p className="crs-demo mt-10">By Demo Instructor</p>
                  </div>
                </div> */}
              </div>
              <div className="col-md-4">
                <button className="crs-env" onClick={() => handleAdd(course)}>
                  Enroll course
                </button>
                <h3 className="crs-h3">Course Details</h3>
                <hr />
                <div className="d-flex flex-column">
                  <div className="d-flex  justify-content-between align-items-center">
                    <div className="d-flex gap-2  justify-content-center align-items-center">
                      <PiClockThin size={20} />
                      <small className="crs-sm">Duration</small>
                    </div>
                    <big className="crs-big">
                      {course?.duration
                        ? `${course.duration} hours`
                        : "Duration not available"}
                    </big>
                  </div>
                  <hr className="crs-hr" />
                  <div className="d-flex  justify-content-between align-items-center">
                    <div className="d-flex gap-2  justify-content-center align-items-center">
                      <PiBookOpenThin size={20} />
                      <small className="crs-sm">Lectures</small>
                    </div>
                    <big className="crs-big">4</big>
                  </div>
                  <hr className="crs-hr" />
                  <div className="d-flex  justify-content-between align-items-center">
                    <div className="d-flex gap-2  justify-content-center align-items-center">
                      <BsCheckCircle size={20} />
                      <small className="crs-sm">Eligibility</small>
                    </div>
                    <big className="crs-big">{course?.eligibility}</big>
                  </div>
                  <hr className="crs-hr" />
                  <div className="d-flex  justify-content-between align-items-center">
                    <div className="d-flex gap-2  justify-content-center align-items-center">
                      <PiBookLight size={20} />
                      <small className="crs-sm">Level</small>
                    </div>
                    <big className="crs-big">Advanced</big>
                  </div>
                </div>
                <h3 className="crs-h3">Popular courses</h3>
                <hr />
                <div className="d-flex flex-column">
                  <div className="d-flex gap-20 ">
                    <div>
                      <img
                        src={require("../images/Hot.webp")}
                        className="img-fluid flex-img"
                      />
                    </div>
                    <div>
                      <h4 className="crs-h4">
                        Web Coding and Apache Basics theory
                      </h4>
                      <big className="b-free mr-20">Free</big>
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <p className="crs-demo mt-10">By Demo Instructor</p>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex gap-20">
                    <div>
                      <img
                        src={require("../images/special.webp")}
                        className="img-fluid flex-img"
                      />
                    </div>
                    <div>
                      <h4 className="crs-h4">
                        Fashion Photography from professional
                      </h4>
                      <strike>₹49.99</strike>
                      <big className="b-price">₹29.99</big>
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#B3BAC2"} />
                      <FaStar className="gap-3" size={15} color={"#B3BAC2"} />
                      <p className="crs-demo mt-10">By Demo Instructor</p>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex gap-20">
                    <div>
                      <img
                        src={require("../images/sheet.webp")}
                        className="img-fluid flex-img"
                      />
                    </div>
                    <div>
                      <h4 className="crs-h4">How to Design Components Right</h4>

                      <big className="b-free mr-20">Free</big>
                      <FaStar className="gap-3" size={15} color={"#B3BAC2"} />
                      <FaStar className="gap-3" size={15} color={"#B3BAC2"} />
                      <FaStar className="gap-3" size={15} color={"#B3BAC2"} />
                      <FaStar className="gap-3" size={15} color={"#B3BAC2"} />
                      <FaStar className="gap-3" size={15} color={"#B3BAC2"} />
                      <p className="crs-demo mt-10">By Demo Instructor</p>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex gap-20">
                    <div>
                      <img
                        src={require("../images/camera.webp")}
                        className="img-fluid flex-img"
                      />
                    </div>
                    <div>
                      <h4 className="crs-h4">
                        How to get comfortable on camera
                      </h4>
                      <strike>₹39.99</strike>
                      <big className="b-price">₹59.99</big>
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <FaStar className="gap-3" size={15} color={"#FFC224"} />
                      <p className="crs-demo mt-10">By Demo Instructor</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </div>
  );
};
export default CourseDetail;
