import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./CartModal.css";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from "../../redux/CartSlice";
import { useNavigate } from "react-router-dom";
const CartModal = ({ cartShow, setCartShow }) => {
  const cartItems = useSelector((state) => state.counter);
  console.log(cartItems);
  const totalFee = useSelector((state) => state.counter.total);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRemoveItem = (item) => {
    dispatch(removeFromCart(item));
  };
  const handleProceedToPay = () => {
    navigate("/checkout");
  };

  return (
    <div>
      <Modal
        show={cartShow}
        onHide={() => setCartShow(false)}
        dialogClassName="modal-30w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            MY CART
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {cartItems?.products.map((item, index) => (
              <div key={index}>
                <div className="d-flex justify-content-around align-items-center bg-color-light">
                  <div className="d-flex gap-24 align-items-center">
                    <div>
                      <img
                        src={item.image}
                        alt={item.name}
                        className="mycart-img"
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <div>
                        <p className="mycart-text">{item.name}</p>{" "}
                      </div>
                      <div>
                        <small className="by">By</small>
                        <big className="cart-p">{item.author}</big>{" "}
                      </div>
                      <div>
                        <big className="mycart-price">₹{item.fee}</big>{" "}
                      </div>
                    </div>
                  </div>
                  <div>
                    <RxCrossCircled
                      size={20}
                      onClick={() => handleRemoveItem(item)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            ))}
          </div>

          <div className="d-flex flex-column bg-color-lgt">
            <h1 className="cart-bill">Bill Details:</h1>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <p className="cart-total mt-24">Items total*</p>
              </div>
              <div>
                <small className="mycart-price">
                  ₹{parseFloat(totalFee).toFixed(2)}
                </small>
              </div>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center">
              <div>
                <p className="cart-total mt-16">Subtotal*</p>
              </div>
              <div>
                <small className="mycart-price">₹700</small>
              </div>
            </div> */}
            <div className="d-flex justify-content-between align-items-center border-dotted">
              <div>
                <p className="cart-total1">TOTAL :</p>
              </div>
              <div>
                <big className="cart-total1">
                  {" "}
                  ₹{parseFloat(totalFee).toFixed(2)}
                </big>
              </div>
            </div>
          </div>

          {/* <button className="myCart-btn1">+ Add Address</button> */}
          <button className="myCart-btn" onClick={handleProceedToPay}>
            Proceed to pay
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CartModal;
