import "../Styles/Navbar.css";
import {
  NavLink,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GoChevronDown } from "react-icons/go";
import { ImHome } from "react-icons/im";
import { CiSearch } from "react-icons/ci";
import { TbLayoutGridAdd } from "react-icons/tb";
import { IoHeartCircleOutline } from "react-icons/io5";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { TfiLocationPin, TfiEmail } from "react-icons/tfi";
import Badge from "@mui/material/Badge";
import { BsPersonCircle } from "react-icons/bs";
import { BiCategory, BiSolidPlusSquare } from "react-icons/bi";
import Offcanvas from "react-bootstrap/Offcanvas";
import LoginModal from "../Modals/LoginModal";
import CartModal from "../Modals/CartModal";
import { RiFacebookCircleLine } from "react-icons/ri";
import { CiLinkedin } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import { useSelector } from "react-redux";
import axiosConfig from "../../Service/axiosConfig";
import { CgProfile } from "react-icons/cg";
import { useAuth } from "../../AuthLogin/AuthContext";

const Navbar = ({ ...props }) => {
  const [openItem, setOpenItem] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [loginShow, setLoginShow] = useState(false);
  const [cartShow, setCartShow] = useState(false);
  const { logout, isLoggedIn } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("query");
  const [query, setQuery] = useState(search || "");
  const navigate = useNavigate();
  const cartCount = useSelector((state) => state.counter.products.length);
  const toggleDropdown = (item) => {
    setOpenItem(openItem === item ? null : item);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [query, setQuery] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("Search query:", query);
  };
  // const ProductData = useSelector((state) => state.counter.products);
  // const initialOrderState = {
  //   products: [],
  //   id: localStorage.getItem("userId"),
  // };
  // const [order, setOrder] = useState(initialOrderState);
  // const GetOrders = async () => {
  //   try {
  //     const response = await axiosConfig.get(
  //       `/accounts/orders/?user=${localStorage.getItem("userId")}`
  //     );
  //     if (response) {
  //       setOrder({
  //         ...order,
  //         id: parseFloat(localStorage.getItem("userId")),
  //         products: ProductData,
  //       });
  //     } else {
  //       console.log("can not find orders");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   GetOrders();
  // }, []);
  const navigateToSearch = () => {
    navigate(`/s?query=${query}`);
  };
  async function handleInputChange(event) {
    const inputValue = event.target.value;
    setQuery(inputValue);
    setSearchParams({ query: inputValue });
  }
  useEffect(() => {
    setQuery(search || "");
  }, [search]);
  const toggleDropdownProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };
  const handleOptionClick = (option) => {
    if (option === "profile") {
      navigate("/profile");
    } else if (option === "logout") {
      logout();
    }
    setIsProfileOpen(false);
  };
  return (
    <>
      <div className="navbar-first">
        <div className="container d-flex justify-content-between pt-2 navbars-icons">
          <div className="navbars-icons">
            <TfiLocationPin className="icon-d" size={30} />
            <span className="span">R.No:05, Jubilee hills, Metr...</span>
            <TfiEmail className="icon-d" size={30} />
            <span className="span">info@krisantec.com</span>
          </div>
          <div className="navbars-icons res-mt">
            <FiPhoneCall className="icon-d" size={30} />
            <span className="span">Call us:+91 8008449532</span>
            <span className="span">
              Follow Us On :{" "}
              <RiFacebookCircleLine className="icon-d" size={20} />
              <CiLinkedin className="icon-d" size={20} />
              {/* <FaWhatsapp className="icon-d" size={20} /> */}
              <FaInstagram className="icon-d" size={20} />
              {/* <FaYoutube className="icon-d" size={20} /> */}
            </span>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light navbar-f">
        <div className="d-flex justify-content-evenly navbar-second">
          <a href="/">
            <img
              src={require("../images/mainLogo.webp")}
              alt="logo"
              className="main-Logo"
            />
          </a>
          <select
            onChange={(e) => (window.location.href = e.target.value)}
            className="mx-2"
          >
            <option value="/">Home</option>
            <option value="/about">Pages</option>
            <option value="/courses">Courses</option>
            <option value="/services">Dashboard</option>
          </select>
          <select
            onChange={(e) => (window.location.href = e.target.value)}
            className="mx-2"
          >
            <option value="/about">Pages</option>
            <option value="/courses">Courses</option>
            <option value="/services">Dashboard</option>
          </select>
          <select
            onChange={(e) => (window.location.href = e.target.value)}
            className="mx-2"
          >
            <option value="/courses">Courses</option>
            <option value="/services">Dashboard</option>
          </select>
          {isLoggedIn && (
            <select
              onChange={(e) => (window.location.href = e.target.value)}
              className="mx-2"
            >
              <option value="/services">Dashboard</option>
            </select>
          )}

          <div className="search-container">
            <div className="dropdown" onClick={() => setIsOpen(!isOpen)}>
              <TbLayoutGridAdd className="icon" color="#5751E1" />
              <span>Categories</span>
              <GoChevronDown className="icon" />
              {isOpen && (
                <div className="dropdown-menu">
                  <div className="dropdown-item">Category 1</div>
                  <div className="dropdown-item">Category 2</div>
                  <div className="dropdown-item">Category 3</div>
                </div>
              )}
            </div>

            <input
              type="text"
              placeholder="Search For Course..."
              value={query}
              onChange={handleInputChange}
              onClick={navigateToSearch}
            />
            <button className="search-button">
              <CiSearch className="icon" />
            </button>
          </div>
          <div className="d-flex align-items-center gap-15">
            <Badge badgeContent={cartCount} color="warning">
              <img
                src={require("../images/cart-icon.webp")}
                alt="bell"
                className="n-icon"
                onClick={() => setCartShow(true)}
              />
            </Badge>
            {isLoggedIn ? (
              // <button className="btn-yellow l-btn" onClick={logout}>
              //   Logout
              // </button>
              <div className="dropdown profile">
                <img
                  src={require("../images/boy-profile.webp")}
                  className="stu-profile dropdown-toggle"
                  alt="Profile"
                  id="profileDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <ul
                  className="dropdown-menu left"
                  aria-labelledby="profileDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleOptionClick("profile")}
                    >
                      Profile
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleOptionClick("logout")}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <button
                className="btn-yellow l-btn"
                onClick={() => setLoginShow(true)}
              >
                Login
              </button>
            )}
            <LoginModal loginShow={loginShow} setLoginShow={setLoginShow} />

            {cartShow && (
              <CartModal cartShow={cartShow} setCartShow={setCartShow} />
            )}
          </div>
        </div>
      </nav>
      <nav className="responsive-navbar">
        <div className="d-flex justify-content-between align-items-center">
          <a href="/">
            <img
              src={require("../images/mainLogo.webp")}
              className="responsive-mainlogo"
            />
          </a>
          <div className="  d-flex align-items-center gap-35">
            <BsPersonCircle size={30} />
            <BiCategory size={30} onClick={handleShow} className="me-2" />
            <Offcanvas show={show} onHide={handleClose} {...props}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  {" "}
                  <img
                    src={require("../images/mainLogo.webp")}
                    className="responsive-mainlogo"
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {/* <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-canvas flex-1 "
                  />
                  <CiSearch size={20} className="" />
                </div> */}
                <form onSubmit={handleSearch} className="search-container">
                  <input
                    type="text"
                    placeholder="Search here..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="search-input"
                  />
                  <button type="submit" className="search-button">
                    <CiSearch className="search-icon" />
                  </button>
                </form>
                <ul className="d-flex flex-column gap-3 text-center">
                  <li
                    className="d-flex justify-content-between can-list mt-4"
                    onClick={() => toggleDropdown("home")}
                  >
                    Home <BiSolidPlusSquare size={20} />
                  </li>
                  {openItem === "home" && (
                    <ul className="sub-menu">
                      <li>Home Option 1</li>
                      <li>Home Option 2</li>
                    </ul>
                  )}

                  <li
                    className="d-flex justify-content-between can-list"
                    onClick={() => toggleDropdown("courses")}
                  >
                    Courses <BiSolidPlusSquare size={20} />
                  </li>
                  {openItem === "courses" && (
                    <ul className="sub-menu">
                      <li>Course Option 1</li>
                      <li>Course Option 2</li>
                    </ul>
                  )}

                  <li
                    className="d-flex justify-content-between can-list"
                    onClick={() => toggleDropdown("pages")}
                  >
                    Pages <BiSolidPlusSquare size={20} />
                  </li>
                  {openItem === "pages" && (
                    <ul className="sub-menu">
                      <li>Page Option 1</li>
                      <li>Page Option 2</li>
                    </ul>
                  )}
                  <li
                    className="d-flex justify-content-between can-list"
                    onClick={() => toggleDropdown("dashboard")}
                  >
                    Dashboard <BiSolidPlusSquare size={20} />
                  </li>
                  {openItem === "dashboard" && (
                    <ul className="sub-menu">
                      <li>Dashboard Option 1</li>
                      <li>Dashboard Option 2</li>
                    </ul>
                  )}
                </ul>
                <div className="d-flex  align-items-center justify-content-center gap-3 text-center ">
                  <div className="can-icons">
                    <FaFacebookF size={20} className="top-center" />
                  </div>
                  <div className="can-icons">
                    <FaTwitter size={20} className="top-center" />
                  </div>
                  <div className="can-icons">
                    <FaInstagram size={20} className="top-center" />
                  </div>
                  <div className="can-icons">
                    <FaWhatsapp size={20} className="top-center" />
                  </div>
                  <div className="can-icons">
                    <FaYoutube size={20} className="top-center" />
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
};
export default Navbar;
