import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";
import { FiPhone } from "react-icons/fi";
import "../Styles/Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-first">
        <div className="row">
          <div className="col-md-3">
            <img
              src={require("../images/footer-logo.webp")}
              alt="footer-logo"
              className="footer-logo"
            />
            <div className="d-flex  mt-47 ">
              <div>
                <SlLocationPin size={40} className="footer-ic" />
              </div>
              <div>
                <p className="li-footer ">
                  John Doe, 456 Elm Street, Suite
                  <br /> 3, Los Angeles, CA 90001, USA.
                </p>
              </div>
            </div>
            <div className="d-flex mt-16">
              <div>
                <FiPhone size={40} className="footer-ic" />
              </div>
              <div>
                <p className="li-footer">+ 91 xxxxx xxxxx</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <h2 className="h2-touch ml-150">Useful Links</h2>
              <hr className="hr-tag ml-150" />
              <ul className="li-footer ml-100">
                <li className="mt-24 mb-15">Careers</li>
                <li className="mb-15">Blog</li>
                <li className="mb-15">Help and Support</li>
                <li className="mb-15">Affiliate</li>
                <li className="mb-15">Investors</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div>
              <h2 className="h2-touch ml-90">Our Company</h2>
              <hr className="hr-tag ml-90" />
              <ul className="li-footer ml-60">
                <li className="mt-24 mb-15">Terms</li>
                <li className="mb-15">Privacy Policy</li>
                <li className="mb-15">Cookie Settings</li>
                <li className="mb-15">Sitemap</li>
                <li className="mb-15">Accessibility Statement</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <h2 className="h2-touch">Get in Touch</h2>
              <hr className="hr-tag" />
              <p className="li-footer footer-p">
                when an unknown printer took galley type and scrambled
              </p>
              <div className="d-flex align-items-center footer-icons-div">
                <FaFacebookF size={20} className="footer-ic" />
                <FaTwitter size={20} className="footer-ic" />
                <FaWhatsapp size={20} className="footer-ic" />
                <FaInstagram size={20} className="footer-ic" />
                <FaYoutube size={20} className="footer-ic" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-strip">
        <div className="li-footer">
          <p className="li-footer">
            © 2010-2024 skillgro.com. All rights reserved.
          </p>
        </div>
        <div>
          <p className="li-footer">Term of Use | Privacy Policy</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
