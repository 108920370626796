import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Navbar from "../NavbarComponents/Navbar";
import Breadcrum from "../Pages/BreadCrum";
import "../Styles/CheckOut.css";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../Service/axiosConfig";
import { useSelector } from "react-redux";

const CheckOut = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    country: "",
    state: "",
    address: "",
    pincode: "",
    mobileno: "",
    email: "",
    additionalInfo: "",
  });

  const cartData = useSelector((state) => state.counter.products);
  const totalFee = useSelector((state) => state.counter.total);
  // Load form data from localStorage when the component mounts
  // useEffect(() => {
  //   const savedFormData = JSON.parse(localStorage.getItem("formData"));
  //   if (savedFormData) {
  //     setFormData(savedFormData);
  //   }
  // }, []);
  // Save form data to localStorage whenever formData changes
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue =
      name === "full_name"
        ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        : value;

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };
  console.log(formData, "formdata");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    setError(null);
    const cartDetails = cartData.map((item, index) => ({
      id: item.id,
      quantity: item.quantity,
    }));
    // Prepare data to send to API
    const orderData = {
      courses: cartDetails,
      full_name: formData.full_name,
      country: formData.country || "",
      state: formData.state || "",
      address: formData.address,
      pincode: formData.pincode,
      mobileno: formData.mobileno,
      email: formData.email,
      additionalInfo: formData.additionalInfo || "",
    };

    try {
      // Retrieve the existing orderId from localStorage
      let orderId = localStorage.getItem("orderId");

      if (orderId) {
        // If orderId exists, update the order with PATCH request
        const response = await axiosConfig.patch(
          `/accounts/orders/${orderId}/`,
          orderData
        );
        console.log(orderId, "order");
        setSuccess(true);
        setIsLoading(false);
        console.log(response.data, "Order updated successfully");
      } else {
        // If orderId doesn't exist, create a new order with POST request
        const response = await axiosConfig.post("/accounts/orders/", orderData);
        setSuccess(true);
        setIsLoading(false);
        console.log(response.data, "Order created successfully");

        // Save the new orderId in localStorage
        const newOrderId = response.data.id;
        if (newOrderId) {
          localStorage.setItem("orderId", newOrderId);
          console.log(newOrderId, "New orderId set in localStorage");
        }
      }
      // After either updating or creating the order, navigate to the cart page
      navigate("/cart");
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred while processing the order:", error);
    }
  };
  console.log(formData, "localFormData");
  return (
    <div>
      <>
        <Navbar />
        <section className="check-breadcrum">
          <Breadcrum active_page="Checkout" />
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="form-container">
                  <h2 className="check-h2">Billing Details</h2>
                  {/* Display error or success messages */}
                  {error && <div style={{ color: "red" }}>{error}</div>}
                  {success && (
                    <div style={{ color: "green" }}>
                      Your order has been placed successfully!
                    </div>
                  )}

                  <form onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="form-group">
                        <label htmlFor="full_name">Full Name*</label>
                        <input
                          className="check-input"
                          type="text"
                          id="full_name"
                          name="full_name"
                          value={formData.full_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="country">Country / Region*</label>
                      <select
                        className="check-select"
                        id="country"
                        name="country"
                        value={formData.country || ""}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Select Country
                        </option>
                        <option value="India">India</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="state">State*</label>
                      <select
                        className="check-select"
                        id="state"
                        name="state"
                        value={formData.state || ""}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Select State
                        </option>
                        <option value="Telangana">Telangana</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="address">Address*</label>
                      <input
                        className="check-input"
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-row">
                      <div className="form-group">
                        <label htmlFor="pincode">Pincode*</label>
                        <input
                          className="check-input"
                          type="text"
                          id="pincode"
                          name="pincode"
                          value={formData.pincode}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Mobile Number*</label>
                        <input
                          className="check-input"
                          type="tel"
                          id="mobileno"
                          name="mobileno"
                          value={formData.mobileno}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email Address*</label>
                        <input
                          className="check-input"
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="additionalInfo" className="check-h2">
                        Additional information (Optional)
                      </label>
                      <textarea
                        className="check-textarea"
                        id="additionalInfo"
                        name="additionalInfo"
                        value={formData.additionalInfo}
                        onChange={handleChange}
                        rows="4"
                      />
                    </div>

                    {/* <div className="form-group">
                      <button
                        type="submit"
                        className="myCart-btn"
                        disabled={isLoading}
                      >
                        {isLoading ? "Submitting..." : "Proceed to Pay"}
                      </button>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <button className="check-btn" onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </div>
  );
};

export default CheckOut;
