import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./LoginModal.css";
import axiosConfig from "../../Service/axiosConfig";
import { useAuth } from "../../AuthLogin/AuthContext";
import { MdClose } from "react-icons/md";
const LoginModal = ({ loginShow, setLoginShow }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [otpTimer, setOtpTimer] = useState(30);
  const [otpValid, setOtpValid] = useState(false);
  const [otps, setOtps] = useState(["", "", "", ""]);
  const { login } = useAuth();

  // const [userId, setUserId] = useState("");
  // Handle closing the modal
  const handleClose = () => {
    setLoginShow(false);
    setOtpShow(false);
    setOtp("");
    setMobileNumber("");
    setError("");
    setMobileError("");
  };
  // Handle mobile number input change
  const handleMobileChange = (e) => {
    setMobileNumber(e.target.value);
  };
  // Start the OTP timer countdown (30 seconds)
  const startOtpTimer = () => {
    let timer = 30;
    const interval = setInterval(() => {
      if (timer > 0) {
        setOtpTimer(timer);
        timer--;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };
  // Validate the mobile number (via API)
  const validateMobile = async () => {
    if (!mobileNumber || mobileNumber.length !== 10) {
      setMobileError("Please enter a valid 10-digit mobile number.");
      return false;
    }

    try {
      setMobileError("");
      setLoading(true);
      // Call your backend API to validate the mobile number
      const response = await axiosConfig.post("/accounts/validate_mobile/", {
        mobile: mobileNumber,
        verify_domain: "elearning.krisantec.com",
      });
      setOtpShow(true);
      startOtpTimer();
    } catch (error) {
      setMobileError("Failed to validate mobile number. Please try again.");
      return false;
    } finally {
      setLoading(false);
    }
  };
  const inputRefs = useRef([]);

  // Handle submitting mobile number and requesting OTP
  const handleSubmitMobile = async () => {
    const isMobileValid = await validateMobile();
    if (!isMobileValid) return;
    setLoading(true);
    setError("");
  };
  console.log(mobileNumber, "mobileNumber");
  // Handle OTP verification via API
  const handleSubmitOtp = async () => {
    const otp = otps.join("");
    if (!otp || otp.length !== 4) {
      setError("Please enter a valid -digit OTP.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      // Verify OTP with the API (replace with your actual API endpoint)
      let responce = await axiosConfig.post("/accounts/verify_otp/", {
        mobile: mobileNumber,
        otp,
      });
      localStorage.setItem("token", responce.data.token);
      localStorage.setItem("user_id", responce.data.user_id);
      setLoginShow(false);
      login();
    } catch (err) {
      setError("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (otpTimer <= 0) {
      setError("OTP has expired. Please request a new one.");
    }
  }, [otpTimer]);
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) {
      // Only allow digits (0-9)
      // Update OTP value for the specific input field
      setOtps((prevOtps) => {
        const newOtps = [...prevOtps];
        newOtps[index] = value;
        return newOtps;
      });

      // Move focus to the next input if the current one is filled
      if (value && index < otps.length - 1) {
        inputRefs.current[index + 1].focus();
      }
      // If the user deletes the value, move focus to the previous input
      else if (!value && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <div>
      <Modal
        dialogClassName="main-container"
        show={loginShow}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <MdClose size={40} className="close-icon" onClick={handleClose} />
          <div className="container main-container">
            <div className="row">
              {!otpShow ? (
                // Mobile Number Input
                <div className="col-md-7 bg-color-blue">
                  <div className="d-flex flex-column align-items-center">
                    <div>
                      <img
                        src={require("../images/footer-logo.webp")}
                        className="login-img"
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        placeholder="+91 xxxxx xxxxx"
                        className="login-input"
                        value={mobileNumber}
                        onChange={handleMobileChange}
                      />
                    </div>
                    {mobileError && (
                      <div className="error-message">{mobileError}</div>
                    )}
                    <div>
                      <input
                        type="submit"
                        value={loading ? "Validating..." : "Continue"}
                        className="login-continue"
                        onClick={handleSubmitMobile}
                        disabled={loading}
                      />
                    </div>
                    <div>
                      <p className="login-p">
                        <small>By continuing, you agree to our</small>
                        <br />
                        Terms of Use & Privacy Policy.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                // OTP Input
                <div className="col-md-7 bg-color-blue">
                  <div className="d-flex flex-column align-items-center">
                    <div>
                      <h1 className="otp-h1">OTP Verification</h1>
                    </div>
                    <div>
                      <p className="otp-num">
                        OTP{" "}
                        <small>
                          has been sent to +91 {mobileNumber.slice(-10)}
                        </small>
                      </p>
                    </div>

                    <div className="d-flex align-items-center">
                      {otps.map((otp, index) => (
                        <input
                          key={index}
                          className="six-div mx-3"
                          value={otp}
                          ref={(el) => (inputRefs.current[index] = el)}
                          onChange={(e) => handleOtpChange(e, index)}
                          maxLength={1}
                          autoFocus={index === 0}
                        />
                      ))}
                    </div>
                    <div>
                      <p className="otp-time">
                        {otpTimer < 10 ? `00:0${otpTimer}` : `00:${otpTimer}`}
                      </p>
                    </div>
                    <div>
                      <p className="login-p">Didn’t get it?</p>
                    </div>
                    <div>
                      <input
                        type="submit"
                        value={loading ? "Verifying OTP..." : "Verify OTP"}
                        className="login-continue"
                        onClick={handleSubmitOtp}
                        disabled={loading}
                      />
                    </div>
                    <p style={{ color: "red" }}>{error}</p>
                  </div>
                </div>
              )}

              {/* Right Side: Login Info Section */}
              <div className="col-md-5 bg-color-white">
                <div>
                  <div>
                    <h1 className="login-h1">LOGIN</h1>
                  </div>
                  <div>
                    <p className="login-p1">
                      Get access to your Orders, Wishlist and Recommendations
                    </p>
                  </div>
                  <div>
                    <img
                      src={require("../images/login-img.webp")}
                      className="login-img1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginModal;
