import React, { useState } from "react";
import Navbar from "../NavbarComponents/Navbar";
import Footer from "./Footer";
import "../Pages/BreadCrum";
import Breadcrum from "../Pages/BreadCrum";
import DataTable from "react-data-table-component";
import "../Styles/Cart.css";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeFromCart } from "../../redux/CartSlice";
import axiosConfig from "../../Service/axiosConfig";

const Cart = () => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [popupType, setPopupType] = useState(null);
  const [error, setError] = useState(null);
  const cartItem = useSelector((state) => state.counter.products);
  const totalFee = useSelector((state) => state.counter.total);
  const dispatch = useDispatch();
  console.log(cartItem, "cartItem");
  const navigate = useNavigate();
  const handleRemoveCartItem = (item) => {
    console.log(item, "item");
    dispatch(removeFromCart(item));
  };
  const cart_columns = [
    {
      name: "Product",
      selector: (row) => (
        <div className="product-cell">
          <img src={row.image} alt={row.title} className="product-image" />
          <div className="product-info">
            <div className="product-title">{row.title}</div>
            <div className="product-author">By {row.author}</div>
          </div>
        </div>
      ),
      grow: 2,
    },
    {
      name: "Price",
      selector: (row) => `₹${row.fee}`,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Subtotal",
      selector: (row) => `₹${row.fee * row.quantity}`,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <RxCrossCircled size={20} onClick={() => handleRemoveCartItem(row)} />
      ),
    },
  ];

  // Dynamically create cart_data from cartItem
  const cart_data = cartItem.map((item, index) => ({
    id: index + 1,
    image: item.image,
    title: item.name,
    author: item.author,
    fee: item.fee,
    quantity: item.quantity,
  }));
  const customStyles = {
    rows: {
      style: {
        minHeight: "102px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#FFFFFF",
        borderBottom: "0.5px solid #B5AAAA",
      },
    },
  };
  const handleNavigateHome = () => {
    //
    navigate("/");
  };
  // const handleNavigateToPay = () => {
  //   navigate("/payment");
  // };
  let orderId = localStorage.getItem("orderId");
  const cartDetails = cartItem.map((item, index) => ({
    id: item.id,
  }));
  console.log(orderId, "or");
  let dataforPay = {
    order_id: orderId,
    courses: cartDetails,
  };
  const HandlePayment = async () => {
    setLoading(true);
    try {
      const data = await axiosConfig.post("/accounts/pay/", dataforPay);

      console.log("data:", data);

      var options = {
        amount: data.data.payment.amount,
        currency: "INR",
        name: "TO ELEARNING STORE",
        description: "Test transaction",
        image: "",
        order_id: data.data.payment.id,
        handler: function (response) {
          handlePaymentSuccess(response);
        },
        prefill: {
          name: localStorage.getItem("name"),
          email: "User's email",
          contact: localStorage.getItem("number"),
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      console.log("options:", options);
      setLoading(false);
      setErrorMsg(null);
      setError("");
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.log(error);
      setPopupType("error");
      setLoading(false);
      if (error.response.data.message) {
        setErrorMsg(error.response.data.message);
        setError(error.response.data.message);
      }
    }
  };

  const handlePaymentSuccess = async (response) => {
    try {
      // Extract necessary payment data from Razorpay and the API response
      const paymentDetails = {
        payment_id: response.razorpay_payment_id,
        razorpay_order_id: response.razorpay_order_id,
        signature: response.razorpay_signature,
      };

      // Step 4: Send the payment data to your backend API for further processing
      const result = await axiosConfig.post("/accounts/payment/success/", {
        response: JSON.stringify(paymentDetails),
      });

      if (result.data.success) {
        console.log("Payment Successful:", result.data);

        navigate("/payment-success"); // Adjust to your success page path
      } else {
        console.error("Payment failed:", result.data.message);
        // Handle failure case (show error, etc.)
      }
    } catch (error) {
      console.error("Error during payment success API call:", error);
      // Handle error (show message to user, etc.)
    }
  };

  return (
    <div>
      <>
        <Navbar />
        <section className="cart-breadcrum text-center">
          <Breadcrum active_page="Cart" />
        </section>
        <section>
          <div className="cart-container">
            <DataTable
              columns={cart_columns}
              data={cart_data}
              customStyles={customStyles}
              responsive
              striped
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 cart-actions">
                <button
                  className="update-cart-button"
                  onClick={handleNavigateHome}
                >
                  Update Cart
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12 d-flex flex-column justify-content-evenly cart-data-table">
                <div>
                  <h1 className="product-title">Cart Totals</h1>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="product-author">Subtotal</div>
                  <div>
                    <p className="product-author">
                      {" "}
                      ₹{parseFloat(totalFee).toFixed(2)}
                    </p>
                  </div>
                </div>
                <hr className="cart-hr" />
                <div className="d-flex justify-content-between">
                  <div className="product-title">Total</div>
                  <div>
                    <p className="product-title">
                      {" "}
                      ₹{parseFloat(totalFee).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-end ">
                  <button className="proced-btn" onClick={HandlePayment}>
                    Proceed To Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    </div>
  );
};

export default Cart;
