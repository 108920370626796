import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://api-elearning.krisantec.com/",
  // timeout: 5000, // Set the timeout for requests (optional)
});

// Add a request interceptor to attach the token from localStorage
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token"); // Retrieve the token from localStorage
  if (token) {
    config.headers["Authorization"] = `Token ${token}`; // Add the token to the request headers
  }

  // config.headers["domain"] = "elearning.krisantec.com";
  config.headers["domain"] = window.location.hostname.replace("www.", "");
  return config;
});

export default axiosInstance;
