import React from "react";
import Breadcrum from "./BreadCrum";
import Footer from "./Footer";
import Navbar from "../NavbarComponents/Navbar";
import "../Styles/Subscriptions.css";
import { RxCrossCircled } from "react-icons/rx";

const Subscriptions = () => {
  return (
    <>
      <Navbar />
      <section className="subscription-breadcrum">
        <Breadcrum active_page="Student Profile" />
      </section>
      <section className="profile-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="top-content">
                <button
                  type="button"
                  className="btn-profile"
                  style={{ backgroundColor: "#EBEBEB" }}
                  onClick={() => {
                    window.location.href = "/profile";
                  }}
                >
                  Personal Details
                </button>
                <button
                  type="button"
                  className="btn-subscription"
                  style={{ backgroundColor: " #FFC224" }}
                >
                  Subscriptions
                </button>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="mt-48 mb-32">Active Courses</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="subscription-card">
                  <div>
                    <img
                      src={require("../images/card-3.webp")}
                      className="sub-img"
                    />
                  </div>
                  <div className="mt-17">
                    <h3 className="sub-text fw-700 col-16">
                      Artificial Intelligence
                    </h3>
                    <p className="sub-text fw-400 col-16">By Lohith Kumar</p>
                  </div>

                  <div className="mt-17">
                    <p className="sub-text col-6d fw-400">Duration: 6 Months</p>
                    <p className="sub-text col-6d fw-400">Expires: 6/20/2025</p>
                  </div>
                  <div>
                    <button type="button" className="sub-active mt-29">
                      Active
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-24">
              <div className="col-md-12">
                <div className="subscription-card">
                  <div>
                    <img
                      src={require("../images/card-3.webp")}
                      className="sub-img"
                    />
                  </div>
                  <div className="mt-17">
                    <h3 className="sub-text fw-700 col-16">
                      Artificial Intelligence
                    </h3>
                    <p className="sub-text fw-400 col-16">By Lohith Kumar</p>
                  </div>

                  <div className="mt-17">
                    <p className="sub-text col-6d fw-400">Duration: 6 Months</p>
                    <p className="sub-text col-6d fw-400">Expires: 6/20/2025</p>
                  </div>
                  <div>
                    <button type="button" className="sub-active mt-29">
                      Active
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-24">
              <div className="col-md-12">
                <div className="subscription-card">
                  <div>
                    <img
                      src={require("../images/card-3.webp")}
                      className="sub-img"
                    />
                  </div>
                  <div className="mt-17">
                    <h3 className="sub-text fw-700 col-16">
                      Artificial Intelligence
                    </h3>
                    <p className="sub-text fw-400 col-16">By Lohith Kumar</p>
                  </div>

                  <div className="mt-17">
                    <p className="sub-text col-6d fw-400">Duration: 6 Months</p>
                    <p className="sub-text col-6d fw-400">Expires: 6/20/2025</p>
                  </div>
                  <div>
                    <button type="button" className="sub-active mt-29">
                      Active
                    </button>
                  </div>
                </div>
                <hr className="border-btm mt-56" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-red mb-32">Expired Courses </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="subscription-card">
                <div>
                  <img
                    src={require("../images/card-2.webp")}
                    className="sub-img"
                  />
                  <div className="overlay"></div>
                </div>
                <div className="mt-17">
                  <h3 className="sub-text fw-700 col-16">
                    Stock Market Investment
                  </h3>
                  <p className="sub-text fw-400 col-16">By Rachana</p>
                </div>

                <div className="mt-17 seperator">
                  <p className="sub-text col-6d fw-400">Duration: 6 Months</p>
                  <p className="sub-text col-6d fw-400">Expires: 6/20/2025</p>
                </div>

                <div>
                  <button type="button" className="sub-renew mt-29">
                    Renew Course
                  </button>
                  <RxCrossCircled size={15} className="sub-cross" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-24">
            <div className="col-md-12">
              <div className="subscription-card">
                <div>
                  <img
                    src={require("../images/card-2.webp")}
                    className="sub-img"
                  />
                  <div className="overlay"></div>
                </div>
                <div className="mt-17 ">
                  <h3 className="sub-text fw-700 col-16">
                    Stock Market Investment
                  </h3>
                  <p className="sub-text fw-400 col-16">By Rachana</p>
                </div>

                <div className="mt-17 seperator">
                  <p className="sub-text col-6d fw-400">Duration: 6 Months</p>
                  <p className="sub-text col-6d fw-400">Expires: 6/20/2025</p>
                </div>
                <div>
                  <button type="button" className="sub-renew mt-29">
                    Renew Course
                  </button>
                  <RxCrossCircled size={15} className="sub-cross" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Subscriptions;
