import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

// Function to load cart from localStorage (default to empty array if not found)
const loadCartFromLocalStorage = () => {
  const savedCart = localStorage.getItem("cart");
  return savedCart ? JSON.parse(savedCart) : [];
};

// Function to load total from localStorage (default to 0 if not found)
const loadTotalFromLocalStorage = () => {
  const savedTotal = localStorage.getItem("total");
  return savedTotal ? JSON.parse(savedTotal) : 0;
};

// Initial state structure for the cart
const initialState = {
  products: loadCartFromLocalStorage(),
  total: loadTotalFromLocalStorage(),
};

// Cart slice
const counterSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Add item to the cart
    addToCart: (state, action) => {
      console.log(action);
      // Avoid adding duplicate items based on the name
      if (state.products.find((item) => item.name === action.payload.name))
        return;

      // Add new item to the products array
      state.products.push({
        name: action.payload.name,
        description: action.payload.description,
        image: action.payload.image,
        fee: action.payload.fee,
        quantity: action.payload.quantity,
        id: action.payload.id,
        added: true,
      });

      // Recalculate total fee based on the products array
      state.total = state.products.reduce(
        (acc, item) => acc + parseFloat(item.fee),
        0
      );

      // Save updated cart and total to localStorage
      saveTotalToLocalStorage(state.total);
      saveCartToLocalStorage(state.products);
    },

    // Remove item from the cart
    removeFromCart: (state, action) => {
      const index = state.products.findIndex(
        (item) => item.name === action.payload.name
      );
      if (index !== -1) {
        state.products.splice(index, 1);

        // Recalculate total fee based on the updated products array
        state.total = state.products.reduce(
          (acc, item) => acc + parseFloat(item.fee),
          0
        );

        // Save updated cart and total to localStorage
        saveTotalToLocalStorage(state.total);
        saveCartToLocalStorage(state.products);
      }
    },
  },
});

// Function to save cart data to localStorage
const saveCartToLocalStorage = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

// Function to save total fee to localStorage
const saveTotalToLocalStorage = (total) => {
  localStorage.setItem("total", JSON.stringify(total));
};

// Export actions and reducer
export const { addToCart, removeFromCart } = counterSlice.actions;
export default counterSlice.reducer;
