import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Provider } from "react-redux";
import store from "./redux/Store";
import Home from "./Components/Pages/Home";
import Cart from "./Components/Pages/Cart";
import CheckOut from "./Components/Pages/CheckOut";
import CourseDetail from "./Components/Pages/CourseDetail";
import ProfileScreen from "./Components/Pages/ProfileScreen";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Subscriptions from "./Components/Pages/Subscriptions";
import SearchCourse from "./Components/Pages/SearchCourse";
import { FormProvider } from "./FormContext/FormContext";
function App() {
  return (
    <Provider store={store}>
      <FormProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />

            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/coursedetail/:id" element={<CourseDetail />} />

            <Route path="/subscription" element={<Subscriptions />} />
            <Route path="/s" element={<SearchCourse />} />
          </Routes>
        </Router>
      </FormProvider>
    </Provider>
  );
}

export default App;
