import React, { useState, useEffect } from "react";
import Navbar from "../NavbarComponents/Navbar";
import Footer from "./Footer";
import Breadcrum from "../Pages/BreadCrum";
import "../Styles/ProfileScreen.css";
import { FaPencil } from "react-icons/fa6";

const ProfileScreen = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    country: "",
    state: "",
    address: "",
    pincode: "",
    mobileno: "",
    email: "",
    city: "",
    gender: "",
    date: "",
  });
  console.log(formData, "form");
  // Load form data from localStorage if available
  useEffect(() => {
    const storedData = localStorage.getItem("formData");
    if (storedData) {
      setFormData(JSON.parse(storedData)); // Parse and set the data from localStorage
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue =
      name === "full_name"
        ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        : value;

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  console.log(formData, "profile");

  return (
    <>
      <Navbar />
      <section className="profile-breadcrum">
        <Breadcrum active_page="Student Profile" />
      </section>
      <section className="profile-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="d-flex justify-content-evenly ">
                <button type="button" className="btn-profile">
                  Personal Details
                </button>
                <button
                  type="button"
                  className="btn-subscription"
                  onClick={() => {
                    window.location.href = "/subscription";
                  }}
                >
                  Subscriptions
                </button>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="row mt-48">
              <div className="col-md-6">
                <div className="profile-card">
                  <div className="round-circle">
                    {" "}
                    <FaPencil size={15} color="#5751E1" />
                  </div>

                  <img
                    src={require("../images/boy-profile.webp")}
                    alt=""
                    className="img-fluid profile-img"
                  />
                  <h2 className="profile-h2">DVITHIK</h2>
                  <p className="profile-p">Hyderabad,India</p>

                  <select id="grade" name="grade" className="grade-select">
                    <option value="4">4th Grade</option>
                  </select>

                  <button type="submit" className="btn-request mt-48">
                    Request a Callback
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <form className="grid-form" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="full_name">Full Name*</label>
                      <input
                        className="check-input"
                        type="text"
                        id="full_name"
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="country">Country / Region*</label>
                      <select
                        className="check-select"
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="India">India</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="state">State*</label>
                      <select
                        className="check-select"
                        id="state"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      >
                        <option value="Telangana">Telangana</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="address">Address*</label>
                      <input
                        className="check-input"
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-row">
                      <div className="form-group">
                        <label htmlFor="pincode">Pincode*</label>
                        <input
                          className="check-input"
                          type="text"
                          id="pincode"
                          name="pincode"
                          value={formData.pincode}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Mobile Number*</label>
                        <input
                          className="check-input"
                          type="tel"
                          id="mobileno"
                          name="mobileno"
                          value={formData.mobileno}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email Address*</label>
                        <input
                          className="check-input"
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="gender">Gender*</label>
                      <select
                        className="check-select"
                        id="gender"
                        name="gender"
                        value={formData.gender || ""}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="city">City*</label>
                      <select
                        className="check-select"
                        id="city"
                        name="city"
                        value={formData.city || ""}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Select City
                        </option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="full_name">Date of Birth*</label>
                      <input
                        className="check-input"
                        type="date"
                        id="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </form>
                <div class="btn-container">
                  <button class="btn-save">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default ProfileScreen;
