import React, { useEffect, useRef, useState } from "react";
import Navbar from "../NavbarComponents/Navbar";
import Slider from "react-slick";
import "../Styles/Home.scss";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { FaStar, FaArrowRight } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import Seperator from "../Seperator/Seperator";
import axiosConfig from "../../Service/axiosConfig";
import { addToCart, removeFromCart } from "../../redux/CartSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          color: "white",
          cursor: "pointer",
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        <FaChevronRight />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-previous-arrow`}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "blue",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          color: "white",
          cursor: "pointer",
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        <FaChevronLeft />
      </div>
    );
  }
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  useEffect(() => {
    axiosConfig
      .get("/accounts/course/")
      .then((response) => {
        const data = response.data.results;
        setCourses(data);
        setLoading(false);
        console.log("Courses data:", data);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
        setLoading(false);
      });
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1048,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 322,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1048,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const AntTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: "#1890ff",
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
      },
    })
  );
  const fetchCategories = async () => {
    try {
      const response = await axiosConfig.get("/catlog/categories/");
      console.log(response.data, "cate");
      if (Array.isArray(response.data.results)) {
        setCategories(response.data.results);
      } else {
        console.error(
          "Unexpected response format for categories:",
          response.data
        );
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-selected": {
        color: "#fff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  const [value, setValue] = React.useState(0);
  const handleAdd = (e, course) => {
    e.stopPropagation();

    console.log(course);
    const {
      name,
      description,
      image: { image },
      fee,
      id,
    } = course;

    dispatch(
      addToCart({
        name,
        description,
        image,
        fee,
        id,
        quantity: 1,
      })
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchCategories();
    fetchSelectedTab("all");
  }, []);
  useEffect(() => {
    if (categories.length > 0) {
      const selectedCategory = value === 0 ? "all" : categories[value - 1]?.id;
      fetchSelectedTab(selectedCategory);
    }
  }, [categories, value]);

  const truncateDescription = (text, maxLength = 150) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const fetchSelectedTab = async (category_ref) => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `/accounts/course/?category_ref=${
          category_ref === "all" ? "" : category_ref
        }`
      );
      if (response.data) {
        setFilteredCourses(response.data.results);
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToCourseDetail = (id) => {
    navigate(`/coursedetail/${id}/`);
  };

  return (
    <div>
      <Navbar />
      <section className="s-blue">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-120">
              <h1 className="h1-white">
                Never Stop
                <img
                  src={require("../images/learn.webp")}
                  className="horn"
                />{" "}
                <br />
                Life Never Stop Teaching
              </h1>
              <p className="p-white">
                Every teaching and learning journey is unique
                <br /> Following We'll help guide your way.
              </p>
              <button className="btn-yellow start-btn">
                Start Free Trial
                <FaArrowRight size={20} style={{ marginLeft: "8px" }} />
              </button>
            </div>
            <div className="col-md-6">
              <img
                src={require("../images/man-1.webp")}
                className="img-fluid f-img"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="lgt-blue">
        <div className="container">
          <div className="d-flex flex-column align-items-center">
            <button className="btn-lgt">Top Class Courses</button>
            <h1 className="h1-black">Explore Our World's Best Courses</h1>
            <p className="p-6D">
              When known printer took a galley of type scrambl edmake
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Box
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              <Box sx={{ bgcolor: "#fff" }}>
                <AntTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Category Tabs"
                  className="mb-4"
                >
                  <AntTab label="All Courses" />
                  {categories.length > 0 ? (
                    categories.map((category, index) => (
                      <AntTab key={category.id} label={category.name} />
                    ))
                  ) : (
                    <AntTab label="Loading categories..." disabled />
                  )}
                </AntTabs>
              </Box>
            </Box>
          </div>
          {filteredCourses.length >= 3 ? (
            <Slider {...settings}>
              {filteredCourses.length > 0 ? (
                filteredCourses.map((course, index) => (
                  <div
                    className="card"
                    key={index}
                    onClick={() => handleNavigateToCourseDetail(course.id)}
                  >
                    <div>
                      {course?.image?.image ? (
                        <img
                          src={course.image.image}
                          alt={course.title}
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src="default-image.jpg"
                          alt={course.title}
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <button className="card-btn mt-24">
                          {course.name}
                        </button>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <FaStar
                            size={20}
                            color={"#FFC224"}
                            className="mt-24"
                          />
                        </div>
                        <div className="mt-24 reviews">
                          ({course.rating} Reviews)
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3 className="h3-black mt-15">
                        {truncateDescription(course.description, 50)}
                      </h3>
                    </div>
                    <div>
                      <p className="cd-p">
                        By {course.trainer_ref.user.full_name}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between align-items-center">
                        <button
                          className="en-now d-flex align-items-center pt-11 pl-20 pb-11"
                          onClick={(e) => handleAdd(e, course)}
                        >
                          Enroll Now
                          <IoIosArrowRoundForward
                            size={20}
                            style={{ marginLeft: "8px" }}
                          />
                        </button>
                      </div>
                      <div>
                        <span className="card-price">${course.fee}</span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No courses available.</div>
              )}
            </Slider>
          ) : (
            <div className="d-flex justify-content-start align-items-center gap-35">
              {filteredCourses.map((course, index) => (
                <div
                  className="card"
                  key={index}
                  onClick={() => handleNavigateToCourseDetail(course.id)}
                >
                  <div>
                    {course?.image?.image ? (
                      <img
                        src={course.image.image}
                        alt={course.title}
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src="default-image.jpg" // Fallback image
                        alt={course.title}
                        className="img-fluid"
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <button className="card-btn mt-24">{course.name}</button>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <FaStar size={20} color={"#FFC224"} className="mt-24" />
                      </div>
                      <div className="mt-24 reviews">
                        ({course.rating} Reviews)
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="h3-black mt-15">
                      {truncateDescription(course.description, 50)}
                    </h3>
                  </div>
                  <div>
                    <p className="cd-p">
                      By {course.trainer_ref.user.full_name || "Unknown"}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        className="en-now d-flex align-items-center pt-11 pl-20 pb-11"
                        onClick={(e) => handleAdd(e, course)}
                      >
                        Enroll Now
                        <IoIosArrowRoundForward
                          size={20}
                          style={{ marginLeft: "8px" }}
                        />
                      </button>
                    </div>
                    <div>
                      <span className="card-price">${course.fee}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <section className="man-sucess">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src={require("../images/man.webp")}
                className="img-fluid s-man"
              />
            </div>
            <div className="col-md-7">
              <h1 className="h1-white">Top trends for the future of work</h1>
              <p className="p-white">
                GenAI and leadership are at the core of today's skills-based
                <br />
                economy. Get the 2024 Global Learning & Skills Trends Report
                <br /> to learn more.
              </p>
              <button className="btn-white">
                Get the report{" "}
                <MdKeyboardDoubleArrowRight
                  size={30}
                  style={{ marginLeft: "8px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="paddings">
        <div className="s-sky-blue">
          <div className="d-flex flex-column align-items-center">
            <div>
              <h1>150+ M</h1>
            </div>
            <div>
              <h3>Downloads</h3>
            </div>
          </div>
          <Seperator />
          <div className="d-flex flex-column align-items-center">
            <div>
              <h1>4.7</h1>
            </div>
            <div>
              <h3>App Rating</h3>
            </div>
          </div>

          <Seperator />
          <div className="d-flex flex-column align-items-center">
            <div>
              <h1>1701+ Cities</h1>
            </div>
            <div>
              <h3>Worldwide</h3>
            </div>
          </div>
          <Seperator />
          <div className="d-flex flex-column align-items-center">
            <div>
              <h1>71 Mins Avg</h1>
            </div>
            <div>
              <h3>Time Spent Daily</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="min-height">
        <div className="container">
          <div className="d-flex flex-column align-items-center">
            <div>
              <img src={require("../images/colon.webp")} className="colon" />
            </div>
            <div>
              <h1 style={{ color: "black" }} className="mb-40">
                Students and Parents Feedback
              </h1>
            </div>
          </div>
          <div className="position-relative w-100">
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings1}
            >
              <div className="d-flex flex-column">
                <div className="slider-div">
                  <p className="slider-p">
                    I can balance my studies and sports well with SkillGro.
                    Videos have helped me understand Math better and be
                    confident.
                  </p>
                  <img
                    src={require("../images/two-colon.webp")}
                    className="tw-colon"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center p-0 flex-1 me-5 mt-3">
                  <div>
                    <img
                      src={require("../images/img2.jpg.webp")}
                      className="img-round"
                    />
                  </div>
                  <div>
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="slider-div">
                  <p className="slider-p">
                    I can balance my studies and sports well with SkillGro.
                    Videos have helped me understand Math better and be
                    confident.
                  </p>
                  <img
                    src={require("../images/two-colon.webp")}
                    className="tw-colon"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center p-0 flex-1 me-5 mt-3">
                  {" "}
                  <div>
                    <img
                      src={require("../images/img2.jpg (1).webp")}
                      className="img-round"
                    />
                  </div>
                  <div>
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column">
                <div className="slider-div">
                  <p className="slider-p">
                    I can balance my studies and sports well with SkillGro.
                    Videos have helped me understand Math better and be
                    confident.
                  </p>
                  <img
                    src={require("../images/two-colon.webp")}
                    className="tw-colon"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center p-0 flex-1 me-5 mt-3">
                  {" "}
                  <div>
                    <img
                      src={require("../images/img2.jpg (1).webp")}
                      className="img-round"
                    />
                  </div>
                  <div>
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                    <FaStar className="gap-3" size={20} color={"#FFC224"} />
                  </div>
                </div>
              </div>
            </Slider>

            <div className="btn-div">
              <button className="button slider-button prev" onClick={previous}>
                <IoIosArrowRoundBack size={30} />
              </button>
              <button className="button slider-button next" onClick={next}>
                <IoIosArrowRoundForward size={30} />
              </button>
            </div>
          </div>
          {/* <div className="d-flex justify-content-around align-items-center p-0 gap-10 mt-35 icons-div">
            <div className="d-flex justify-content-between align-items-center p-0 flex-1 me-5">
              <div>
                <img
                  src={require("../images/img2.jpg.webp")}
                  className="img-round"
                />
              </div>
              <div>
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center p-0 flex-1 me-5">
              {" "}
              <div>
                <img
                  src={require("../images/img2.jpg (1).webp")}
                  className="img-round"
                />
              </div>
              <div>
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
                <FaStar className="gap-3" size={20} color={"#FFC224"} />
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Home;
